<template>
  <div id="BomEdit">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer testing-bom-edit"
      v-model="flag"
      width="600"
    >
      <p slot="header">
        物料清单
        <span class="text-primary" style="margin-left: 10px">
          {{
          formd.Party == 1 ? "委托方" : formd.Party == 2 ? "受委托方" : ""
          }}
        </span>
      </p>
      <div class="bk-drawer-body">
        <div style="position: relative; min-height: 300px">
          <Form
            :label-width="100"
            :model="formd"
            :rules="rules"
            @submit.native.prevent
            inline
            ref="form"
          >
            <FormItem label="委托单：" prop="OrderId" required style="margin-bottom:10px;">
              <Button
                @click="fastPickOrder"
                ghost
                size="small"
                style="margin-bottom: 8px"
                type="primary"
                v-if="$store.state.order.Id && !def.OrderId"
              >{{ $store.state.order.Code }}</Button>
              <Input
                :value="formd.OrderCode"
                @on-clear="unPickOrder"
                clearable
                readonly
                v-if="!def.OrderId"
              >
                <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
              </Input>
              <div v-if="def.OrderId">{{def.OrderCode}}</div>
            </FormItem>
            <FormItem label="测试项目：" prop="ItemId" required style="margin-bottom:10px;">
              <Select
                :disabled="!formd.OrderId || def.ItemId>0"
                @on-change="onItemId"
                v-if="!def.ItemId"
                v-model="formd.ItemId"
              >
                <Option
                  :disabled="item._disabled"
                  :key="`item-d-${item.Id}`"
                  :value="item.Id"
                  v-for="item in orderItems"
                >{{ item.Name }}</Option>
              </Select>
              <div v-if="def.ItemId">{{def.ItemName}}</div>
            </FormItem>
            <FormItem class="long" style="margin-bottom:10px;">
              <Button
                @click="addBomItem(1)"
                ghost
                size="small"
                style="margin-right:10px"
                type="primary"
                v-show="formd.Party == 1"
              >添加样品</Button>
              <Button @click="addBomItem(99)" ghost size="small" type="primary">添加其他物料</Button>
            </FormItem>
          </Form>
          <Spin class="bk-loading" fix v-if="loading">
            <div>
              <Icon type="ios-loading"></Icon>
              <div>Loading</div>
            </div>
          </Spin>
          <Spin class="bk-failed" fix v-if="failed">
            <div @click="req">
              <Icon type="md-refresh"></Icon>
              <p>Refresh</p>
            </div>
          </Spin>
          <div
            class="text-sub"
            style="padding: 0 20px"
            v-if="formd.ItemId && formd1.length == 0 && formd99.length == 0"
          >暂无物料</div>
          <Divider orientation="left" v-show="formd1.length > 0">样品</Divider>
          <Form
            :key="`bom-1-${i}`"
            :label-width="100"
            :model="formd1[i]"
            :ref="`bom_1_${i}`"
            :rules="rules1"
            @submit.native.prevent
            class="bom-form"
            inline
            v-for="(item, i) in formd1"
          >
            <!-- <div class="text-info text-xs" v-if="formd1[i].Status == 0">新增</div>
            <div class="text-warning text-xs" v-if="formd1[i].Status == 2">变更</div>-->
            <FormItem label="名称：" prop="Name" required>
              <Input class="bom-name-input" v-model="formd1[i].Name" />
            </FormItem>
            <FormItem label="编码：" prop="Code" required>
              <Input
                @on-enter="addBomItem2(1, i)"
                class="inline-close-input"
                placeholder="输入回车继续增加"
                v-model="formd1[i].Code"
              />
              <Button @click="delBomItem(1, i)" class="fr close" ghost icon="md-close" type="text"></Button>
            </FormItem>
          </Form>
          <Divider orientation="left" v-show="formd99.length > 0">其他物料</Divider>
          <Form
            :key="`bom-99-${i}`"
            :label-width="100"
            :model="formd99[i]"
            :ref="`bom_99_${i}`"
            :rules="rules99"
            @submit.native.prevent
            class="bom-form"
            inline
            v-for="(item, i) in formd99"
          >
            <FormItem label="名称：" prop="Name" required>
              <Input class="bom-name-input" v-model="formd99[i].Name" />
            </FormItem>
            <FormItem label="数量：" prop="Qty" required>
              <Input
                @on-enter="addBomItem2(99, i)"
                class="inline-close-input"
                placeholder="输入回车继续增加"
                v-model="formd99[i].Qty"
              />
              <Button @click="delBomItem(99, i)" class="fr close" icon="md-close" type="text"></Button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="bk-drawer-footer">
        <Button :loading="posting" @click="ok" type="primary">保存</Button>
      </div>
    </Drawer>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
export default {
  components: { PickOrder },
  data() {
    return {
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      def: {},
      formd: {},
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
        ItemId: [{ required: true, type: "number", message: " " }],
      },
      orderItems: [],
      formd1: [],
      rules1: {
        Name: [{ required: true, message: " " }],
        Code: [{ required: true, message: " " }],
      },
      formd99: [],
      rules99: {
        Name: [{ required: true, message: " " }],
        Qty: [
          {
            validator: (rule, value, callback) => {
              /^\d+$/.test(value) ? callback() : callback(new Error(" "));
            },
          },
        ],
      },
      delData: [],
      validCount: 0,
    };
  },
  methods: {
    open(data) {
      if (data) {
        this.def = data;
        this.formd = {
          OrderId: data.OrderId,
          OrderCode: data.OrderCode,
          ItemId: data.ItemId,
          Party: data.Party,
          // ItemName: data.ItemName
        };
        this.req();
        this.reqItems();
      } else {
      }
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.clear();
    },
    clear() {
      this.id = 0;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.formd1 = [];
      this.formd99 = [];
      this.delData = [];
    },
    ok() {
      this.validCount = 0;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.countValid();
          for (let i in this.formd1) {
            this.$refs[`bom_1_${i}`][0].validate((valid) => {
              console.log(`bom_1_${i}`, valid);
              if (valid) {
                this.countValid();
              }
            });
          }
          for (let i in this.formd99) {
            this.$refs[`bom_99_${i}`][0].validate((valid) => {
              console.log(`bom_99_${i}`, valid);
              if (valid) {
                this.countValid();
              }
            });
          }
        }
      });
    },
    //
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryByOrder", {
          params: {
            OrderId: this.formd.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            let lst = res.data;
            let items = [];
            let childNums = {};
            for (let a of lst) {
              if (a.ParentId > 0) {
                childNums[a.ParentId] == undefined
                  ? (childNums[a.ParentId] = 0)
                  : childNums[a.ParentId]++;
              }
            }
            for (let a of lst) {
              if (a.ParentId > 0) {
                items.push(a);
              } else if (childNums[a.Id] == undefined) {
                items.push(a);
              }
            }
            this.orderItems = items;
          }
        });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsBom/QueryByItemAndParty", {
          params: {
            OrderId: this.formd.OrderId,
            ItemId: this.formd.ItemId,
            Party: this.formd.Party,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            let a = [],
              b = [];
            for (let x of res.data) {
              if (x.Type == 1) {
                a.push(x);
              } else if (x.Type == 99) {
                b.push(x);
              }
            }
            this.formd1 = a;
            this.formd99 = b;
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    countValid() {
      this.validCount++;
      let bomCount = this.formd1.length + this.formd99.length;
      if (bomCount == 0) {
        this.$Message.warning("请添加物料");
        return;
      }
      if (this.validCount == bomCount + 1) {
        this.postData();
      }
    },
    postData() {
      this.posting = true;
      let param = {
        ItemId: this.formd.ItemId,
        Datas: [],
      };
      param.Datas = param.Datas.concat(this.formd1, this.formd99, this.delData);
      this.$axios
        .post("/Api/BtmsBom/SaveEx", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("编辑成功");
              this.$emit("on-upd");
            } else {
              this.$Message.success("新增成功");
              this.$emit("on-add");
            }
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 订单
    fastPickOrder() {
      this.formd.OrderId = this.$store.state.order.Id;
      this.$set(this.formd, "OrderCode", this.$store.state.order.Code);
      this.reqItems();
    },
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
        this.reqItems();
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
      this.orderItems = [];
      this.formd.ItemId = 0;
      this.formd1 = [];
      this.formd99 = [];
      this.delData = [];
    },
    // 项目
    onItemId(id) {
      if (id) {
        this.req();
      }
    },
    // 物料
    addBomItem2(type, i) {
      if (type == 1) {
        if (!this.formd1[i].Code) {
          return;
        }
        this.formd1.push({
          OrderId: this.formd.OrderId,
          OrderCode: this.formd.OrderCode,
          ItemId: this.formd.ItemId,
          Party: this.formd.Party,
          Type: 1,
          Name: "",
          Code: "",
          Qty: 1,
        });
        this.$nextTick(() => {
          this.$refs[`bom_1_${this.formd1.length - 1}`][0].$el
            .getElementsByClassName("bom-name-input")[0]
            .getElementsByTagName("input")[0]
            .focus();
        });
      } else if (type == 99) {
        if (!this.formd99[i].Qty) {
          return;
        }
        this.formd99.push({
          OrderId: this.formd.OrderId,
          OrderCode: this.formd.OrderCode,
          ItemId: this.formd.ItemId,
          Party: this.formd.Party,
          Type: 99,
          Name: "",
          Code: "",
          Qty: 1,
        });
        this.$nextTick(() => {
          this.$refs[`bom_99_${this.formd99.length - 1}`][0].$el
            .getElementsByClassName("bom-name-input")[0]
            .getElementsByTagName("input")[0]
            .focus();
        });
      }
    },
    addBomItem(type) {
      if (!this.formd.ItemId) {
        this.$Message.warning("请先选择测试项目");
        return;
      }
      if (type == 1) {
        this.formd1.push({
          OrderId: this.formd.OrderId,
          OrderCode: this.formd.OrderCode,
          ItemId: this.formd.ItemId,
          Party: this.formd.Party,
          Type: 1,
          Name: "",
          Code: "",
          Qty: 1,
        });
      } else if (type == 99) {
        this.formd99.push({
          OrderId: this.formd.OrderId,
          OrderCode: this.formd.OrderCode,
          ItemId: this.formd.ItemId,
          Party: this.formd.Party,
          Type: 99,
          Name: "",
          Code: "",
          Qty: 1,
        });
      }
    },
    delBomItem(type, index) {
      let dels = [];
      if (type == 1) {
        dels = this.formd1.splice(index, 1);
      } else if (type == 99) {
        dels = this.formd99.splice(index, 1);
      }
      if (dels[0].Id) {
        dels[0].Isdel = 1;
        this.delData = this.delData.concat(dels);
      }
    },
  },
};
</script>
<style lang="less">
.testing-bom-edit {
  .ivu-form-item {
    margin-right: 0;
    width: 50%;
  }
  .ivu-form-item.long {
    width: 100%;
  }
  .inline-close-input {
    width: calc(~"100% - 42px");
  }
  .bom-form + .bom-form {
    border-top: 1px dashed #e8eaec;
  }
  .bom-form {
    padding-top: 10px;
    .ivu-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>
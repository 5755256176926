<template>
  <div id="PickOrder">
    <Modal
      :styles="{ top: '50px' }"
      :title="title"
      @on-cancel="close"
      class="pick-order"
      v-model="flag"
      width="700"
    >
      <div class="flex" style="margin-bottom:10px">
        <Input clearable placeholder="委托单号" style="margin-left: 10px" v-model="searchData1.Code" />
        <Input clearable placeholder="客户名称" style="margin-left: 10px" v-model="searchData1.ClientName" />
        <Select clearable placeholder="状态" style="margin-left: 10px" v-model="searchData1.Status">
          <Option
            :key="item.key"
            :value="item.key"
            v-for="item in $designConst.order.StatusLst"
          >{{ item.name }}</Option>
        </Select>
        <Button class="flex-shrink" icon="md-search" type="primary" style="margin-left: 10px" @click="search"></Button>
      </div>
      <Table
        :columns="cols"
        :data="list"
        :height="tableHeight"
        :highlight-row="!multiple"
        :loading="loading"
        @on-current-change="onCurrentChange"
        @on-row-click="onRowClick"
        @on-select="onSelect"
        @on-select-all="onSelectAll"
        @on-select-all-cancel="unSelectAll"
        @on-select-cancel="unSelect"
      ></Table>
      <div slot="footer">
        <div class="flex">
          <Page
            :current="pi"
            :page-size="ps"
            :total="all"
            @on-change="(index) => onChangePage(index, 0)"
            @on-page-size-change="(size) => onChangePage(1, size)"
            style="flex: 1; text-align: left; line-height: 32px"
          />
          <Button @click="close" type="text">取消</Button>
          <Button @click="ok" type="primary">确定</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "选择委托单",
      type: String,
    },
    multiple: Boolean,
    type: [String, Number],
    status: [String, Number],
  },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      tableHeight: 300,
      flag: false,
      loading: false,
      pi: 1,
      ps: 10,
      all: 0,
      list: [],
      cols: [
        { title: "委托单号", key: "Code", width: 160 },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status: this.$designConst.order.StatusBadge[params.row.Status],
                text: params.row.StatusDescr,
              },
            });
          },
        },
        { title: "委托单位", key: "ClientName" },
        { title: "测试项目", key: "TestItems" },
      ],
      picked: [],
      obj: {},
    };
  },
  mounted: function () {
    this.tableHeight = this.$root.$el.clientHeight - 280;
    if (this.multiple) {
      this.cols.unshift({ type: "selection", width: 60, align: "center" });
    } else {
      this.cols.unshift({
        title: "\\",
        width: 30,
        render: (h, params) =>
          this.obj[params.row.Id]
            ? h("Icon", {
                props: { type: "md-checkmark", color: "#2d8cf0" },
              })
            : h("span"),
      });
    }
  },
  methods: {
    open(lst) {
      this.flag = true;
      if (lst && lst.length > 0) {
        this.picked = lst;
        this.picked.map((v) => {
          this.obj[v.Id] = true;
        });
      }
      this.reqLst();
    },
    close() {
      this.flag = false;
      this.picked = [];
      this.obj = {};
    },
    ok() {
      let data = this.$util.copy(this.picked);
      this.$emit("on-ok", data);
      this.close();
    },
    search() {
      this.searchData2 = this.$util.copy(this.searchData1);
      this.pi = 1;
      this.reqLst();
    },
    reqLst() {
      let params = Object.assign(
        {
          PageIndex: this.pi,
          PageSize: this.ps,
          UserType: this.type,
          Status: this.status,
        },
        this.searchData2
      );
      this.loading = true;
      this.$axios
        .get("/Api/BtmsOrder/QueryPg", { params: params })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            this.list = this.handleList(res.data.DataSet);
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error("网络错误");
        });
    },
    handleList(list) {
      for (let i in list) {
        if (this.obj[list[i].Id]) {
          this.multiple
            ? (list[i]._checked = true)
            : (list[i]._highlight = true);
        } else {
          this.multiple
            ? (list[i]._checked = false)
            : (list[i]._highlight = false);
        }
      }
      return list;
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    // -----------------------------------------单选
    onCurrentChange(currentRow, oldRow) {
      let obj = {};
      obj[currentRow.Id] = true;
      this.picked = [currentRow];
      this.obj = obj;
    },
    // -----------------------------------------多选
    onRowClick(row, index) {
      if (!this.multiple) {
        return;
      }
      if (!this.obj[row.Id]) {
        this.onSelect(this.picked, row);
      } else {
        this.unSelect(this.picked, row);
      }
      this.list = this.handleList(this.$util.copy(this.list));
    },
    onSelect(selection, row) {
      let picked = this.$util.copy(this.picked);
      if (!this.obj[row.Id]) {
        this.obj[row.Id] = true;
        picked.push(row);
      }
      this.picked = picked;
    },
    onSelectAll(selection) {
      let picked = this.$util.copy(this.picked);
      for (let i in selection) {
        if (!this.obj[selection[i].Id]) {
          this.obj[selection[i].Id] = 1;
          picked.push(selection[i]);
        }
      }
      this.picked = picked;
    },
    unSelect(selection, row) {
      let picked = this.$util.copy(this.picked);
      if (this.obj[row.Id]) {
        this.obj[row.Id] = 0;
        let index = picked.findIndex((v) => v.Id == row.Id);
        if (index != -1) {
          picked.splice(index, 1);
        } else {
          console.log("selContacts: unSelect error");
        }
      }
      this.picked = picked;
    },
    unSelectAll(selection) {
      let picked = this.$util.copy(this.picked);
      let cur_page_obj = {};
      for (let i in this.list) {
        if (this.obj[this.list[i].Id]) {
          this.obj[this.list[i].Id] = 0;
          cur_page_obj[this.list[i].Id] = 1;
        }
      }
      this.picked = picked.filter((v) => !cur_page_obj[v.Id]);
    },
    delSelect(row) {
      let index = this.picked.findIndex((v) => v.Id == row.Id);
      if (index != -1) {
        this.obj[this.picked[index].Id] = false;
        this.picked.splice(index, 1);
        this.list = this.handleList(this.$util.copy(this.list));
      }
    },
    clearSelect() {
      for (let i in this.obj) {
        this.obj[i] = false;
      }
      this.picked = [];
      this.list = this.handleList(this.$util.copy(this.list));
    },
  },
};
</script>
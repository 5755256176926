<template>
  <div class="bk-page" id="Bom">
    <div class="bk-page-scroll">
      <div class="bk-page-lst" style="padding-bottom: 5px">
        <Form :label-width="80" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="委托单号">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="测试项目">
            <Select clearable v-model="searchData1.ItemId">
              <Option
                :key="`item-${item.Id}`"
                :value="item.Id"
                v-for="item in orderItems"
              >{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="btns" style="width: 250px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="onCollapseAll" type="default">全部收起</Button>
            <!-- <Dropdown @on-click="toAdd" style="margin-left: 10px" v-if="$able.map.BtmsBomAdd">
              <Button ghost type="primary">
                新增物料
                <Icon type="ios-arrow-down"></Icon>
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem :name="1">委托方</DropdownItem>
                <DropdownItem :name="2">机构方</DropdownItem>
              </DropdownMenu>
            </Dropdown>-->
          </FormItem>
        </Form>
      </div>
      <Card dis-hover style="margin-top: 10px" v-if="tableLoading">
        <div class="bk-loading text-primary" style="padding: 10px; text-align: center">
          <div>
            <Icon type="ios-loading"></Icon>
            <div>Loading</div>
          </div>
        </div>
      </Card>
      <Card dis-hover style="margin-top: 10px" v-if="tableData.length == 0 && !tableLoading">
        <div class="text-sub" style="padding: 20px; text-align: center">暂无数据</div>
      </Card>
      <div v-show="tableData.length > 0 && !tableLoading">
        <Card
          :class="item._collapse?'grp collapse':'grp'"
          :key="`grp-${i}`"
          dis-hover
          v-for="(item, i) in tableData"
        >
          <p slot="title">
            <Icon
              :type="item._collapse?'ios-arrow-forward':'ios-arrow-down'"
              @click="onCollapse(i)"
            />
            <span>委托单号：</span>
            <span class="text-primary">{{ item.OrderCode }}</span>
            ，测试项目：
            <span class="text-primary">{{ item.ItemName }}</span>
            <span v-if="item.BomChecked == 1">
              ，
              <span class="text-success">已签字生效</span>
            </span>
            <span v-if="item.BomChecked==0&&item.FlowCode=='BOM-SIGN'&&item.FlowPartyaAction==0">
              ，
              <span class="text-warning">待客户签字</span>
            </span>
            <span
              v-if="item.BomChecked==0&&item.FlowCode=='BOM-SIGN'&&item.FlowPartyaAction==1&&item.FlowPartybAction==0"
            >
              ，
              <span class="text-warning">待我方签字</span>
            </span>
            <Divider type="vertical" />
            <Button
              @click="pushSign(item)"
              size="small"
              type="primary"
              v-if="item.PushAble"
            >发起物料清单确认</Button>
            <Button @click="openFlow(item)" size="small" type="primary" v-if="item.showFlow">查看确认流程</Button>
          </p>
          <template v-if="item.SubItems.length == 0">
            <div class="flex">
              <span style="flex-shrink: 0">委托方：</span>
              <span class="text-off" v-if="item.PartyABoms.length == 0">暂无物料</span>
              <div>
                <span
                  :key="`party-a-bom-${j}`"
                  style="white-space: nowrap"
                  v-for="(bom, j) in item.PartyABoms"
                >
                  <span style="display: inline-block">
                    <span class="text-info">{{ bom.Name }}</span>
                    <span class="text-sub" v-if="bom.Type == 1">({{ bom.Code }})</span>
                    <span class="text-sub" v-if="bom.Type == 99">* {{ bom.Qty }}</span>
                  </span>
                  <b style="vertical-align: top" v-if="j < item.PartyABoms.length - 1">，</b>
                  <b style="vertical-align: top" v-if="j == item.PartyABoms.length - 1">；</b>
                </span>
                <Icon
                  @click="openGrp(item, 1)"
                  color="#2d8cf0"
                  size="16"
                  style="cursor: pointer; margin-left: 5px"
                  type="md-create"
                  v-if="item.BomChecked == 0 && $able.map.BtmsBomAdd"
                />
                <Icon
                  color="#c5c8ce"
                  size="16"
                  style="cursor: not-allowed; margin-left: 5px"
                  title="已签字生效，不可编辑"
                  type="md-create"
                  v-if="item.BomChecked == 1"
                />
              </div>
            </div>
            <div class="flex">
              <span style="flex-shrink: 0">机构方：</span>
              <span class="text-off" v-if="item.PartyBBoms.length == 0">暂无物料</span>
              <div>
                <span
                  :key="`party-a-bom-${j}`"
                  style="white-space: nowrap"
                  v-for="(bom, j) in item.PartyBBoms"
                >
                  <span style="display: inline-block">
                    <span class="text-info">{{ bom.Name }}</span>
                    <span class="text-sub" v-if="bom.Type == 1">({{ bom.Code }})</span>
                    <span class="text-sub" v-if="bom.Type == 99">* {{ bom.Qty }}</span>
                    <!-- <span v-if="bom.ChangeApplyTime" class="text-sub text-xs"><br/>{{bom.ChangeApplyTime}}</span> -->
                  </span>
                  <b style="vertical-align: top" v-if="j < item.PartyBBoms.length - 1">，</b>
                  <b style="vertical-align: top" v-if="j == item.PartyBBoms.length - 1">；</b>
                </span>
                <Icon
                  @click="openGrp(item, 2)"
                  color="#2d8cf0"
                  size="16"
                  style="cursor: pointer; margin-left: 5px"
                  type="md-create"
                  v-if="item.BomChecked == 0 && $able.map.BtmsBomAdd"
                />
                <Icon
                  color="#c5c8ce"
                  size="16"
                  style="cursor: not-allowed; margin-left: 5px"
                  title="已签字生效，不可编辑"
                  type="md-create"
                  v-if="item.BomChecked == 1"
                />
              </div>
            </div>
          </template>
          <template v-if="item.SubItems.length > 0">
            <div :key="j" class="bom-item-parent" v-for="(b, j) in item.SubItems">
              <b>{{b.Name}}：</b>
              <div class="flex">
                <span style="flex-shrink: 0">委托方：</span>
                <span class="text-off" v-if="b.PartyABoms.length == 0">暂无物料</span>
                <div>
                  <span
                    :key="`party-a-bom-${j}`"
                    style="white-space: nowrap"
                    v-for="(bom, j) in b.PartyABoms"
                  >
                    <span style="display: inline-block">
                      <span class="text-info">{{ bom.Name }}</span>
                      <span class="text-sub" v-if="bom.Type == 1">({{ bom.Code }})</span>
                      <span class="text-sub" v-if="bom.Type == 99">* {{ bom.Qty }}</span>
                    </span>
                    <b style="vertical-align: top" v-if="j < b.PartyABoms.length - 1">，</b>
                    <b style="vertical-align: top" v-if="j == b.PartyABoms.length - 1">；</b>
                  </span>
                  <Icon
                    @click="openGrp2(item, b, 1)"
                    color="#2d8cf0"
                    size="16"
                    style="cursor: pointer; margin-left: 5px"
                    type="md-create"
                    v-if="item.BomChecked == 0 && $able.map.BtmsBomAdd"
                  />
                  <Icon
                    color="#c5c8ce"
                    size="16"
                    style="cursor: not-allowed; margin-left: 5px"
                    title="已签字生效，不可编辑"
                    type="md-create"
                    v-if="item.BomChecked == 1"
                  />
                </div>
              </div>
              <div class="flex">
                <span style="flex-shrink: 0">机构方：</span>
                <span class="text-off" v-if="b.PartyBBoms.length == 0">暂无物料</span>
                <div>
                  <span
                    :key="`party-a-bom-${j}`"
                    style="white-space: nowrap"
                    v-for="(bom, j) in b.PartyBBoms"
                  >
                    <span style="display: inline-block">
                      <span class="text-info">{{ bom.Name }}</span>
                      <span class="text-sub" v-if="bom.Type == 1">({{ bom.Code }})</span>
                      <span class="text-sub" v-if="bom.Type == 99">* {{ bom.Qty }}</span>
                      <!-- <span v-if="bom.ChangeApplyTime" class="text-sub text-xs"><br/>{{bom.ChangeApplyTime}}</span> -->
                    </span>
                    <b style="vertical-align: top" v-if="j < b.PartyBBoms.length - 1">，</b>
                    <b style="vertical-align: top" v-if="j == b.PartyBBoms.length - 1">；</b>
                  </span>
                  <Icon
                    @click="openGrp2(item, b, 2)"
                    color="#2d8cf0"
                    size="16"
                    style="cursor: pointer; margin-left: 5px"
                    type="md-create"
                    v-if="item.BomChecked == 0 && $able.map.BtmsBomAdd"
                  />
                  <Icon
                    color="#c5c8ce"
                    size="16"
                    style="cursor: not-allowed; margin-left: 5px"
                    title="已签字生效，不可编辑"
                    type="md-create"
                    v-if="item.BomChecked == 1"
                  />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <Card dis-hover style="margin-top: 10px">
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </Card>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
    <push-bom-sign @on-post="reqTable" ref="PushBomSign" />
    <flow @bom-confirm="reqTable" ref="flow" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import edit from "./edit";
import PushBomSign from "@/components/PushBomSign";
import flow from "./flow";
export default {
  components: { PickOrder, edit, PushBomSign, flow },
  data() {
    return {
      orderItems: [],
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (window.toTabData && window.toTabData.FlowCode == "BOM-SIGN") {
        if (this.$refs.flow.flag) {
          this.$refs.flow.clear();
        }
        this.$refs.flow.open(window.toTabData.OrderId, window.toTabData.ItemId);
      }
      if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code,
        };
        this.reqItems();
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
    },
    // 初始化搜索
    initSearch() {
      this.reSearchData();
      this.pageIndex = 1;
      this.onSearch();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reqTable() {
      if (this.$able.map.BtmsBomQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsBom/QueryPgGrp", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            let data = res.data.DataSet || [];
            let lst = [];
            try {
              for (let x of data) {
                let item = {
                  OrderCode: x.OrderCode,
                  OrderId: x.OrderId,
                  ItemCode: x.ItemCode,
                  ItemId: x.ItemId,
                  ItemName: x.ItemName,
                  PartyABoms: [],
                  PartyBBoms: [],
                  BomChecked: x.BomChecked,
                  FlowCode: x.FlowCode,
                  FlowId: x.FlowId,
                  FlowPartyaAction: x.FlowPartyaAction,
                  FlowPartybAction: x.FlowPartybAction,
                  FlowStatus: x.FlowStatus,
                  SubItems: x.SubItems,
                  PushAble:
                    this.$able.map.BtmsOrderBomSign &&
                    x.FlowCode == "TESTING-SYLLABUS-SIGN" &&
                    x.FlowStatus == 2,
                  showFlow: x.BomChecked || x.FlowCode == "BOM-SIGN",
                };
                if (x.SubItems.length == 0) {
                  for (let y of x.Boms) {
                    if (y.Party == 1) {
                      item.PartyABoms.push(y);
                    } else if (y.Party == 2) {
                      item.PartyBBoms.push(y);
                    }
                  }
                } else {
                  let obj = {};
                  for (let i in x.SubItems) {
                    obj[x.SubItems[i].Id] = i;
                    x.SubItems[i].PartyABoms = [];
                    x.SubItems[i].PartyBBoms = [];
                  }
                  for (let y of x.Boms) {
                    if (y.Party == 1) {
                      x.SubItems[obj[y.ItemId]].PartyABoms.push(y);
                    } else if (y.Party == 2) {
                      x.SubItems[obj[y.ItemId]].PartyBBoms.push(y);
                    }
                  }
                }
                lst.push(item);
              }
            } catch (err) {
              console.log(err);
            }
            this.tableData = lst;
            this.recordCount = res.data.RecordCount;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
        this.reqItems();
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
      this.searchData1.ItemId = 0;
      this.orderItems = [];
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryByOrder", {
          params: {
            OrderId: this.searchData1.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.filter((v) => v.ParentId == 0);
          }
        });
    },
    // ==========
    openFlow(item) {
      this.$refs.flow.open(item.OrderId, item.ItemId);
    },
    toAdd(party) {
      this.$refs.edit.open({
        Party: party,
      });
    },
    openGrp(row, party) {
      this.$refs.edit.open({
        OrderId: row.OrderId,
        OrderCode: row.OrderCode,
        ItemId: row.ItemId,
        ItemName: row.ItemName,
        Party: party,
      });
    },
    openGrp2(item, subitem, party) {
      this.$refs.edit.open({
        OrderId: item.OrderId,
        OrderCode: item.OrderCode,
        ItemId: subitem.Id,
        ItemName: subitem.Name,
        Party: party,
      });
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsBom/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    pushSign(item) {
      this.$refs.PushBomSign.open(item.OrderId, item.ItemId);
    },
    onCollapse(i) {
      this.tableData[i]._collapse = !this.tableData[i]._collapse;
      this.tableData = this.tableData.concat([]);
    },
    onCollapseAll() {
      for (let i in this.tableData) {
        this.tableData[i]._collapse = true;
      }
      this.tableData = this.tableData.concat([]);
    },
  },
};
</script>
<style lang="less">
#Bom {
  .ivu-card-head,
  .ivu-card-body {
    padding: 10px;
  }
  .ivu-card-head {
    padding-left: 30px;
    position: relative;
    .ivu-icon-ios-arrow-down,
    .ivu-icon-ios-arrow-forward {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 5px;
      top: 12px;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .grp.collapse {
    .ivu-card-head {
      border-bottom: none;
    }
    .ivu-card-body {
      display: none;
    }
  }
  .bom-item-parent + .bom-item-parent {
    margin-top: 10px;
  }
  .color-block {
    display: inline-block;
    width: 18px;
    height: 18px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 15%);
    border-radius: 2px;
    margin: 0 10px 0 5px;
    position: relative;
    top: 4px;
  }
  .grp {
    margin: 10px 0;
  }
  .status-1 {
    color: #2d8cf0;
  }
  .status-2 {
    color: #ff9900;
  }
  .ivu-card-head p {
    font-size: 14px;
    height: auto;
    line-height: 24px;
  }
}
</style>

<template>
  <div id="BomFlow">
    <Drawer :transfer="false" class="bk-sub-drawer" v-model="flag" width="400">
      <div class="ivu-drawer-header-inner" slot="header">
        <span v-if="flow">{{flow.OrderCode}}&nbsp;-&nbsp;{{flow.ItemName}}&nbsp;-&nbsp;</span>
        流程
      </div>
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="reqFlow">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="text-sub" v-if="flow === null">暂无数据</div>
      <div class="bk-drawer-body" v-if="flow">
        <div class="flow-item" dis-hover>
          <div class="flex">
            <b>{{ flow.Name }}</b>
            <span class="text-primary" style="margin-left: 10px">
              {{
              flow.Tips
              }}
            </span>
          </div>
          <div class="flex">
            <div class="label">发起时间：</div>
            <div>
              <div>{{ flow.CreateTime }}</div>
            </div>
          </div>
          <div class="flex">
            <div class="label">委托方：</div>
            <div>
              <div class="text-primary">{{ flow.PartyaActionTips }}</div>
              <div class="text-sub text-xs">{{ flow.SignTimeA }}</div>
            </div>
          </div>
          <div class="flex" v-if="flow.PartybActionTips">
            <div class="label">我方：</div>
            <div>
              <div class="text-primary">{{ flow.PartybActionTips }}</div>
              <div class="text-sub text-xs">{{ flow.SignTimeB }}</div>
            </div>
          </div>
        </div>
        <attach-and-trace ref="attachAndTrace" />
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button @click="toSign" type="info" v-if="flow.PartybAction == 0">物料清单签字</Button>
      </div>
    </Drawer>
    <sign @on-ok="onSign" ref="sign" />
  </div>
</template>
<script>
import attachAndTrace from "../../components/attach-and-trace.vue";
import Sign from "@/components/Sign";
export default {
  components: { attachAndTrace, Sign },
  data() {
    return {
      ABLE: false,
      orderId: 0,
      itemId: 0,
      flag: false,
      loading: false,
      failed: false,
      flow: "",
    };
  },
  methods: {
    open(orderId, itemId) {
      this.orderId = orderId;
      this.itemId = itemId;
      this.flag = true;
      this.reqFlow();
    },
    close() {
      this.flag = false;
      this.clear();
    },
    clear() {
      this.ABLE = false;
      this.orderId = 0;
      this.itemId = 0;
      this.loading = false;
      this.$refs.attachAndTrace.clear();
      this.flow = "";
    },
    reqFlow() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsFlow/GetLast", {
          params: {
            FlowCode: "BOM-SIGN",
            OrderId: this.orderId,
            ItemId: this.itemId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.failed = false;
            this.flow = res.data;
            this.ABLE =
              this.$able.map.BtmsOrderBomSign &&
              this.flow.Code == "BOM-SIGN" &&
              this.flow.PartyaAction == 1 &&
              this.flow.PartybAction == 0;
            if (res.data) {
              this.$nextTick(() => {
                this.$refs.attachAndTrace.init(res.data.OrderId, res.data.Id, {
                  delAttachFlag: this.$able.map.BtmsOrderDelFlowAtta,
                });
              });
            }
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    //
    toSign() {
      this.$refs.sign.open();
    },
    onSign(data) {
      this.$axios
        .post("/Api/BtmsBom/BomConfirm", {
          OrderId: this.flow.OrderId,
          ItemId: this.flow.ItemId,
          SignBase64: data[1],
        })
        .then((res) => {
          this.$refs.sign.posting = false;
          if (res.result) {
            this.$Message.success("签名成功");
            this.reqFlow();
            this.$refs.sign.close();
            this.$emit("bom-confirm");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.$refs.sign.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.bk-drawer-body {
  padding-top: 20px;
}
.flow-item {
  // border-bottom: 1px dashed #dcdee2;
  .flex {
    margin-bottom: 10px;
  }
  .label {
    width: 5em;
    text-align: right;
  }
}
.sub-titles {
  font-weight: bold;
  position: relative;
}
.sub-titles::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 2em;
  background-color: #2d8cf0;
  bottom: 0;
  left: 0;
}
.attach-item {
  background: #f8f8f9;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  a {
    text-decoration: underline;
  }
}
</style>
